import { default as browserZT0TnZcV43Meta } from "/opt/build/repo/src/pages/browser.vue?macro=true";
import { default as camera5bHt2oPgPpMeta } from "/opt/build/repo/src/pages/camera.vue?macro=true";
import { default as convertZ1jQltcQdEMeta } from "/opt/build/repo/src/pages/convert.vue?macro=true";
import { default as indexb6PXQqCOTmMeta } from "/opt/build/repo/src/pages/dev/index.vue?macro=true";
import { default as mocks3dZr2W1GB2Meta } from "/opt/build/repo/src/pages/dev/mocks.vue?macro=true";
import { default as indexc981B4HL6uMeta } from "/opt/build/repo/src/pages/history/index.vue?macro=true";
import { default as index9NYIf0P7HnMeta } from "/opt/build/repo/src/pages/home/index.vue?macro=true";
import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as indexogWrHBJCGBMeta } from "/opt/build/repo/src/pages/profile/index.vue?macro=true";
import { default as referrals27d3cZbt8NMeta } from "/opt/build/repo/src/pages/profile/referrals.vue?macro=true";
import { default as profileEKJBvWLjJeMeta } from "/opt/build/repo/src/pages/profile.vue?macro=true";
import { default as indexSydSRBvxlSMeta } from "/opt/build/repo/src/pages/topup/index.vue?macro=true";
import { default as qr4KT6UUQraLMeta } from "/opt/build/repo/src/pages/topup/qr.vue?macro=true";
import { default as topupemzLm5KE3iMeta } from "/opt/build/repo/src/pages/topup.vue?macro=true";
import { default as addressdxmxSmblaoMeta } from "/opt/build/repo/src/pages/transfer/address.vue?macro=true";
import { default as checkbLp17xTarqMeta } from "/opt/build/repo/src/pages/transfer/check.vue?macro=true";
import { default as indexVGdvdyUk2zMeta } from "/opt/build/repo/src/pages/transfer/index.vue?macro=true";
import { default as status8apPjcgRSCMeta } from "/opt/build/repo/src/pages/transfer/status.vue?macro=true";
import { default as transfer7Lup2YNspqMeta } from "/opt/build/repo/src/pages/transfer.vue?macro=true";
export default [
  {
    name: "browser",
    path: "/browser",
    meta: browserZT0TnZcV43Meta || {},
    component: () => import("/opt/build/repo/src/pages/browser.vue")
  },
  {
    name: "camera",
    path: "/camera",
    meta: camera5bHt2oPgPpMeta || {},
    component: () => import("/opt/build/repo/src/pages/camera.vue")
  },
  {
    name: "convert",
    path: "/convert",
    meta: convertZ1jQltcQdEMeta || {},
    component: () => import("/opt/build/repo/src/pages/convert.vue")
  },
  {
    name: "dev",
    path: "/dev",
    component: () => import("/opt/build/repo/src/pages/dev/index.vue")
  },
  {
    name: "dev-mocks",
    path: "/dev/mocks",
    meta: mocks3dZr2W1GB2Meta || {},
    component: () => import("/opt/build/repo/src/pages/dev/mocks.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexc981B4HL6uMeta || {},
    component: () => import("/opt/build/repo/src/pages/history/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: index9NYIf0P7HnMeta || {},
    component: () => import("/opt/build/repo/src/pages/home/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexMUmX8mAfjNMeta || {},
    component: () => import("/opt/build/repo/src/pages/index.vue")
  },
  {
    name: profileEKJBvWLjJeMeta?.name,
    path: "/profile",
    meta: profileEKJBvWLjJeMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile.vue"),
    children: [
  {
    name: "profile",
    path: "",
    meta: indexogWrHBJCGBMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/index.vue")
  },
  {
    name: "profile-referrals",
    path: "referrals",
    meta: referrals27d3cZbt8NMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/referrals.vue")
  }
]
  },
  {
    name: topupemzLm5KE3iMeta?.name,
    path: "/topup",
    meta: topupemzLm5KE3iMeta || {},
    component: () => import("/opt/build/repo/src/pages/topup.vue"),
    children: [
  {
    name: "topup",
    path: "",
    component: () => import("/opt/build/repo/src/pages/topup/index.vue")
  },
  {
    name: "topup-qr",
    path: "qr",
    component: () => import("/opt/build/repo/src/pages/topup/qr.vue")
  }
]
  },
  {
    name: transfer7Lup2YNspqMeta?.name,
    path: "/transfer",
    meta: transfer7Lup2YNspqMeta || {},
    component: () => import("/opt/build/repo/src/pages/transfer.vue"),
    children: [
  {
    name: "transfer-address",
    path: "address",
    meta: addressdxmxSmblaoMeta || {},
    component: () => import("/opt/build/repo/src/pages/transfer/address.vue")
  },
  {
    name: "transfer-check",
    path: "check",
    meta: checkbLp17xTarqMeta || {},
    component: () => import("/opt/build/repo/src/pages/transfer/check.vue")
  },
  {
    name: "transfer",
    path: "",
    meta: indexVGdvdyUk2zMeta || {},
    component: () => import("/opt/build/repo/src/pages/transfer/index.vue")
  },
  {
    name: "transfer-status",
    path: "status",
    meta: status8apPjcgRSCMeta || {},
    component: () => import("/opt/build/repo/src/pages/transfer/status.vue")
  }
]
  }
]