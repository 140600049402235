<script setup lang="ts">
const { $pwa } = useNuxtApp()

onBeforeMount(() => {
  // получаем данные для всего приложения
})

onMounted(() => {
  if ($pwa?.offlineReady)
    console.log('App ready to work offline')
})

useHead({
  titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - ${APP_NAME}` : `${APP_NAME}`),
});
</script>
<template>
  <div class="app">
    <NuxtLoadingIndicator />
    <NuxtPwaAssets />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
