/**
 * Is in dev env
 */
export const IS_DEV = import.meta.env.DEV;

/**
 * Main app name
 */
export const APP_NAME = 'Antarctic Wallet';


/**
 * Default app locale
 */
export const DEFAULT_LOCALE = 'ru';

/**
 * Is debug mode available
 * it can be started by adding GET attr to url `debug=true`
 */
export const hasDebug = () => {
  const route = useRoute();
  return route.query?.debug === 'true';
};
